<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <legend *ngIf="isParentUsers" class="fobuk-fieldset__legend govuk-fieldset__legend--l">
      <span class="govuk-caption-l">{{ workplace.name }}</span>
      <span class="govuk-caption-l">(Workplace ID: {{ workplace.nmdsId | removeTrailingWhitespace }})</span>

      <h1 class="govuk-fieldset__heading">Users ({{ users.length }})</h1>
    </legend>
    <h2 *ngIf="!isParentUsers" class="govuk-heading-m">Users ({{ users.length }})</h2>
    <div *ngIf="showSecondUserBanner" [ngClass]="{ 'govuk-grid-column-full govuk-!-padding-left-0': isParentUsers }">
      <app-inset-text [color]="'todo'">
        <h3 class="govuk-heading-s">You should add a second user</h3>
        <p>
          Adding a second user will give Skills for Care another person to contact at your workplace should you be
          unavailable.
        </p>
      </app-inset-text>
    </div>
  </div>
  <div class="govuk-grid-column-one-third govuk-util__align-right">
    <a
      *ngIf="canAddUser"
      role="button"
      draggable="false"
      class="govuk-button"
      [routerLink]="['/workplace', workplace.uid, 'user', 'create']"
    >
      Add a user
    </a>
  </div>
</div>
<ng-container *ngIf="users.length > 0; else noUsers">
  <app-user-table
    [users]="users"
    [canViewUser]="canViewUser"
    [workplace]="workplace"
    [userPermissionsTypes]="userPermissionsTypes"
  ></app-user-table>
</ng-container>

<ng-template #noUsers>
  <p>There are no user accounts.</p>
</ng-template>
